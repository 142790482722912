import { useEffect } from 'react';

const CaptureUTMParameters = () => {
  useEffect(() => {
    // Function to get UTM parameters from URL
    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');

    if (utmSource != null || utmMedium != null || utmCampaign != null) {
      const utmData = {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
      };
      if (typeof window !== 'undefined') {
        // Save UTM parameters in local storage for persistence
        localStorage.setItem('utmParams', JSON.stringify(utmData));
      }
    }
  });
};

export default CaptureUTMParameters;
