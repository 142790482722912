/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { usePathname } from '@/navigation';
import { useLocale } from 'next-intl';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

let reactPlugin = null;
let appInsights = null;

const initialize = (connectionString) => {
  reactPlugin = new ReactPlugin();

  appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
};

const handleRouteChange = (url: string) => {
  if (!reactPlugin || url.startsWith(`/health`)) {
    return;
  }

  reactPlugin.trackPageView({
    uri: url,
  });
};

export default function AppInsightsContextProvider({ children }: Props) {
  const [isInitialized, setInitialized] = useState(false);
  const pathname = usePathname();
  const locale = useLocale();

  useEffect(() => {
    const connectionString = process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING;

    if (!connectionString || !!appInsights) {
      return;
    }

    initialize(connectionString);

    handleRouteChange(`/${locale}${pathname}`);

    setInitialized(true);
  }, [pathname]);

  useEffect(() => {
    if (!isInitialized) {
      return undefined;
    }

    handleRouteChange(`/${locale}${pathname}`);

    return () => {
      handleRouteChange(`/${locale}${pathname}`);
    };
  }, [isInitialized, pathname]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        appInsights={reactPlugin}
        onError={() => <h1>There has been an error in the application.</h1>}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}
