import React, { PropsWithChildren, useMemo } from 'react';
import Head from 'next/head';
import Favicon from '@/components/atoms/favicon/favicon';
import { ApplicationType, Footer, Header, socialMediaLinks } from '@into-discovery/browsewebshared';
import { usePathname, useRouter } from '@/navigation';
import { getLegalURLs, getPageURLs, localesList } from '@/utils/helpers/menu-items';
import { useLocale, useTranslations } from 'next-intl';
import colorStyles from '@/styles/utils/variables.module.scss';
import { useSearchParams } from 'next/navigation';
import { appBranding } from '@/data/application-branding';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

type Props = {
  children: React.ReactNode;
};

function DefaultLayout({ children }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const currentLocale = useLocale();
  const pathName = usePathname();

  const translate = useTranslations(`Common`);

  const renderData = useMemo(() => {
    const pageURLs = getPageURLs({ currentLocale });
    const legalURLs = getLegalURLs({ currentLocale });

    const appSettings = {
      googleApiKey: process.env.MAPS_API_KEY,
      branding: appBranding,
      b2cFlag: true,
      applicationType: ApplicationType.IntoStudy,
    };

    const headerProps = {
      router,
      searchParams,
      menuItems: pageURLs,
      localesList: localesList,
      translate,
      isShowSearchBar: pathName !== '/',
      applicationSettings: appSettings,
      currentLocale,
      pathname: pathName,
      applicationURL: process.env.NEXT_PUBLIC_INTO_STUDY_URL,
      headerApplyURL: `/${currentLocale}/search`,
      headerApplyCopyText: translate(`header.nav.apply`),
    };

    const footerProps = {
      intoLogoURL: process.env.NEXT_PUBLIC_INTO_STUDY_URL,
      pageURLs,
      legalURLs,
      currentLocale,
      socialURLs: socialMediaLinks,
      themeColor: colorStyles.intoRed,
      themeCopyColor: colorStyles.white,
      textICP: '沪ICP备14028023号-3',
      codePSB: '31010502007241',
      showB2C: true,
    };

    return { appSettings, headerProps, footerProps };
  }, [currentLocale, pathName, router, searchParams, translate]);

  return (
    <section className="root-layout">
    <main className="page-wrapper has-sidebar-nav">
      <Head>
        <Favicon />
      </Head>
      <Header headerProps={renderData.headerProps} />
      <section className="page-section">{children}</section>
      <Footer footerProps={renderData.footerProps} />
    </main>
  </section>
  );
}

// Wrap the component with LaunchDarkly provider and export
export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LD_CLIENT_SDK_KEY,
})(DefaultLayout) as React.FC<PropsWithChildren>;
