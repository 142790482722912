import IMenuItem from '@into-discovery/browsewebshared/src/models/header/IMenuItem';

export type MenuItemsConfig = {
  currentLocale: string;
};

export function getPageURLs(config: MenuItemsConfig): IMenuItem[] {
  const about = {
    url: `/${config.currentLocale}/about-into`,
    translationKey: 'header.nav.about-into',
  };

  const studyAbroad = {
    url: `/${config.currentLocale}/study-abroad`,
    translationKey: 'header.nav.study-abroad',
  };

  const studyPrograms = {
    url: `/${config.currentLocale}/study-programs`,
    translationKey: 'header.nav.study-program',
  };

  const unis = {
    url: `/${config.currentLocale}/universities`,
    translationKey: 'header.nav.universities',
  };

  const admissions = {
    url: `/${config.currentLocale}/admissions`,
    translationKey: 'header.nav.admissions',
  };

  const contact = {
    url: `/${config.currentLocale}/contact`,
    translationKey: 'header.nav.contact',
  };

  const items = [about, studyAbroad, studyPrograms, unis, admissions, contact];

  return items;
}

export function getLegalURLs(config: MenuItemsConfig): IMenuItem[] {
  const privacy = {
    url: `/${config.currentLocale}/legal-and-privacy-policy/into`,
    translationKey: 'footer.link.privacy',
  };

  const terms = {
    url: `/${config.currentLocale}/terms`,
    translationKey: 'footer.link.terms',
  };

  const cookiesPolicy = {
    url: `/${config.currentLocale}/legal-and-privacy-policy/cookies-policy`,
    translationKey: 'footer.link.cookies-policy',
  };

  const governance = {
    url: `/${config.currentLocale}/governance`,
    translationKey: 'footer.link.governance',
  };

  const accessibility = {
    url: `/${config.currentLocale}/accessibility-statement`,
    translationKey: 'footer.link.accessibility',
  };

  const items = [privacy, terms, cookiesPolicy, governance, accessibility];

  return items;
}

export const localesList = [
  {
    text: 'En',
    value: 'en',
  },
  {
    text: '中文',
    value: 'zh',
  },
];
