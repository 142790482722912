import React from 'react';

export default function Favicon() {
  return (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/next-assets/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/next-assets/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/next-assets/favicons/favicon-16x16.png"
      />
      <link rel="mask-icon" href="/next-assets/favicons/safari-pinned-tab.svg" color="#E2001A" />
      <link rel="icon" type="image/x-icon" href="/next-assets/favicons/favicon.ico" />
      <meta name="apple-mobile-web-app-title" content="INTO Study" />
      <meta name="application-name" content="INTO Study" />
      <meta name="msapplication-TileColor" content="#E2001A" />
      <meta name="msapplication-config" content="/next-assets/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#E2001A" />
      <link rel="manifest" href="/next-assets/favicons/manifest.webmanifest" />
    </>
  );
}
