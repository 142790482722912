'use client';

import React, { ReactNode } from 'react';
import AppInsightsContextProvider from '@/context/AppInsightsContextProvider';
import DefaultLayout from '../default-layout/default-layout';

type Props = {
  children: ReactNode;
};

export default function AppRouterLayout({ children }: Props) {
  return (
    <AppInsightsContextProvider>
      <DefaultLayout>{children}</DefaultLayout>
    </AppInsightsContextProvider>
  );
}