export type EnumToObject<TEnum, TItem = string> = Record<keyof TEnum, TItem>;

export enum SupportedLanguages {
  en = `en`,
  zh = `zh`,
}

export const SupportedLanguageLocales: EnumToObject<typeof SupportedLanguages, string> = {
  en: `en-US`,
  zh: `zh-CN`,
};

export const supportedLanguagesList = Object.keys(SupportedLanguages);
export const locales = supportedLanguagesList;
export const defaultLocale = SupportedLanguages.en;

// this is to support list of locales usage inside pages and components
export const i18n = {
  locales: supportedLanguagesList,
};

export const getCurrentSafeLanguage = (lang: string): string =>
  SupportedLanguages[lang] || defaultLocale;
