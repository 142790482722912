import(/* webpackMode: "eager" */ "/app/node_modules/@into-discovery/browsewebshared/src/styles/_normalize.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@into-discovery/browsewebshared/src/styles/gallery.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@into-discovery/browsewebshared/src/styles/body.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_providers/IntlProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/atoms/loading-spinner/loading-spinner.module.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/layout/app-router-layout/app-router-layout.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/styles.scss");
